export const environment = {
    DOMAIN:'dev-ms.taskopad.com',
    production: false,
    API_URL: 'https://ms-bff.taskopad.com/',
    url: 'https://ms-bff.taskopad.com/',
    userUrl: 'https://ms-user.taskopad.com/',
    subscriptionUrl: 'https://ms-sub.taskopad.com/',
    discussionURL: 'https://ms-discussion.taskopad.com/',
    socketUrl: 'https://ms-discussion.taskopad.com/',
    RAZOR_PAY_KEY: 'rzp_test_cv1yezr6ERzQ7s',
    RECAPTCHA_V3_SITE_KEY: '6LcZRDMnAAAAAEcsehsK5KAFft-1qDBXD_wdjlI_',
    RECAPTCHA_V2_SITE_KEY: '6LcTbeAcAAAAAGPYWbevOiIDQuvOjKp7HUPVxHSq',
    buildVersion: '2.0.1',
    firebase: {
        apiKey: 'AIzaSyAK4D6IWoau37BfWWDHeKHn6vKsquyu5m0',
        authDomain: 'task0pad-stage.firebaseapp.com',
        projectId: 'task0pad-stage',
        storageBucket: 'task0pad-stage.appspot.com',
        messagingSenderId: '163883225284',
        appId: '1:163883225284:web:affd684d14a74375f37991',
        measurementId: 'G-XD3RZTNGSH',
    },

    
};